@import url('https://fonts.googleapis.com/css?family=Raleway');
@import "../../assets/__styles__/colors";

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  color: $midnightblue !important;
  font-size: 1rem;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

small {
  color: gray;
}

@media screen and (max-width:769px) {
  body {
    font-size: 0.75rem;
  }
}