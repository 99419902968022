.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-absolute-horizontally {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}