@import url('https://fonts.googleapis.com/css?family=Bilbo+Swash+Caps');

.header-sticky-top {
  position: absolute;
  top: -7vh;
  width: fit-content;
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(26,20,74,1) 0%, rgba(59,52,120,1) 100.2% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Bilbo Swash Caps', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 7vh;
  &:before {
    content: "Devotee Association";
  }
}

.p-menubar {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: saturate(180%) blur(10px);
  border: none;
  border-radius: 0;
}

@media screen and (max-width:769px) {
  .header-sticky-top {
    top: -5vh;
    font-size: 6vh;
    &:before {
      content: "DevA";
    }
  }

  .p-dialog {
    width: 94vw !important;
    .p-dialog-header .p-dialog-title {
      font-size: 5vmin !important;
    }
  }
}
