.temple-overview:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

@media screen and (max-width:769px) {
  .header-sticky-top {
    top: -5vh;
    font-size: 6vh;
    &:before {
      content: "DevA";
    }
  }
}
