$building-unit: 8px;
$building-unit-x2: $building-unit * 2;

.has-padding {
  padding: $building-unit;
}

.has-margin {
  margin: $building-unit;
}

.has-padding-sides {
  padding-left: $building-unit;
  padding-right: $building-unit;
}
.has-padding-top-bottom {
  padding-top: $building-unit;
  padding-bottom: $building-unit;
}

.has-margin-sides {
  margin-left: $building-unit;
  margin-right: $building-unit;
}
.has-margin-top-bottom {
  margin-top: $building-unit;
  margin-bottom: $building-unit;
}

.has-no-padding {
  padding: 0;
}

.has-no-margin {
  margin: 0;
}

.width-full {
  width: 100%;
}

.width-fit-content {
  width: fit-content;
}

.central-stage {
  margin-top: 3rem;
  background: url("/assets/images/devas-background.png") no-repeat top;
  background-size: auto;
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.p-menubar-mobile-active {
  &~.central-stage {
    z-index: -1;
  }
  &~* .central-stage {
    z-index: -1;
  }
}