@import "../../assets/__styles__/BuildingBlocks";
@import "../../assets/__styles__/colors";

.react-tel-input {
  width: 230px !important;
  .phone-input {
    background-color: transparent !important;
    width: 225px !important;
  }
}

#otp-input {
  background-color: transparent;
}

.p-card.p-component {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: saturate(180%) blur(10px);
  border-radius: 11px;
}

.p-divider-content {
  background-color: transparent !important;
}

@media screen and (max-width:769px) {
  .p-card.p-component {
    width: 99%;
  }
}
