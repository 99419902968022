@import "../../assets/__styles__/BuildingBlocks";
@import "../../assets/__styles__/BasicAlignments";
@import "../../assets/__styles__/colors";

.App {
  text-align: center;
}

.App-logo {
  height: 48px;
  pointer-events: none;
}

.router-link-button {
  text-decoration: none;
}

.p-avatar {
  /*border: 3px solid red;
  border-image: linear-gradient(210deg, #21D4FD 0%, $midnightblue 100%) 5;*/
  background: #fff linear-gradient(225deg, #ea188d 0%, #0a1a1a 50%, #0c4366 100%);
  padding: 2px;
}

.p-toast {
  text-align: left;
  z-index: 2;
}

.form-field-error-icon {
  background-color: darkred !important;
  color: whitesmoke !important;
}

.react-tel-input {
  .phone-input {
    height: 3rem !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo.animate {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
